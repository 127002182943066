import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import NotFoundImage from "../images/not-found-image.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center">
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={NotFoundImage}
        />
        <Link to="/" className="text-2xl text-gray-900 hover:bg-gray-50 inline-block my-8 p-3">
          Go Back to Home
        </Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
